<template>
    <div>
        <h2>{{ $t("monte.article_valider_saillie") }}</h2>
        <div class="box">

            <SeasonInput v-model="seasonSelected" @change="changeSeason" />

            <CustomTable
                :id_table="id_table"
                :items="a_valider"
                :busy.sync="table_busy"
                primaryKey="increment"
                :hide_if_empty="true"
                :hrefsRoutes="config_table_hrefs"
                :rawColumns="rawColumnNames"
                ref="aValiderTable"
            />
        </div>
    </div>
</template>


<script type="text/javascript">
    import { EventBus } from 'EventBus'
    import Invoice from "@/mixins/Invoice.js"
    import Navigation from "@/mixins/Navigation.js"
    import Common from '@/assets/js/common.js'

    export default {
        name: "aValiderSaillie",
        mixins: [Invoice, Navigation],
        props: {
            options: {
                type: Object,
                default: () => ({
                    type: 'Invoice',
                    id: null,
                })
            }
        },
        data () {
            return {
				id_table: "contract_saillie_a_valider",
                a_valider: null,
                table_busy: true,
                events_tab: {
                    'TableAction::goToValideFacturationAValider': this.valideFacturationAValider,
                },
                rawColumnNames: ['horse_nom', 'contract_note'],
                config_table_hrefs: {
					'contract_num': {
						routeUniqueName: 'ContractForm',
						routeName: 'ContractFormv2',
						params: {
							contract_id: 'contract_id'
						}
					},
                    'horse_nom': {
                        routeUniqueName: 'horseFiche',
                        routeName: 'horseFiche',
                        params: {
                            horse_id: 'horse_id'
                        }
                    }
                },
                seasonSelected: null,
            }
		},
        mounted() {
            this.init_component()
        },
        methods: {
            async init_component() {
                this.changeSeason()
            },
            async loadAValider(season_id) {
                this.table_busy = true

                const a_valider = await this.loadFacturationAValiderMonteSaillie(season_id)
                this.a_valider = await this.formatAValider(a_valider)

                this.table_busy = false
            },
			async formatAValider(a_valider) {
				let avalider = []
                if(!a_valider) {
                    return []
                }
                let increment = 0

                if(a_valider) {
                    for(let i = 0; i < a_valider.length; i++) {
                        increment ++
                        let temp = a_valider[i]

                        temp.contract_label = temp.articles_label
                        temp.increment = increment

                        temp.href = "horseFiche"
                        temp.params = { horse_id: temp.horse_id }

                        temp.ht_no_format = temp.ht
                        // temp.ht = await this.priceFormat(temp.ht, temp.author_currency)

                         if(temp.acte_saillie) {
                            temp.date_saillie = Date.parseTz(temp.acte_saillie.actes_date)
                        }

                        temp.status = this.getTrad(temp.status)
                        temp.sms_status = temp.sms_status ? Common.translateSeasonMareStatus(temp.sms_status) : null

                        avalider.push(temp)
                    }
                }

                return avalider
			},
            async valideFacturationAValider(horse_actes) {
                let paramsToSend = {};
                paramsToSend['details'] = []
                paramsToSend['pensions'] = []

                if(horse_actes) {
                    for(let i = 0; i < horse_actes.length; i++) {
                        let discount_type = horse_actes[i].discount_type ? {
                            invoicediscounttype_id: horse_actes[i].discount_type,
                            invoicediscounttype_type: horse_actes[i].discount_type == 1 ? 'pourcentage' : 'numeraire'
                        } : null
                        
                        paramsToSend.details.push({
                            author_id: horse_actes[i].author_id,
                            tiers_id: horse_actes[i].tiers_id,
                            qte: 1,
                            htunit: horse_actes[i].ht_before_discount,
                            vat: horse_actes[i].vat,
                            horse_id: horse_actes[i].horse_id,
                            articles_id: horse_actes[i].articles_id,
                            actes_id: horse_actes[i].actes_id,
                            description: '',
                            invoicedetails_avenantarticles: horse_actes[i].avenantarticles_id,
                            invoicedetails_contract: horse_actes[i].contract_id,
                            label: horse_actes[i].articles_label,
                            avenantarticles_id: horse_actes[i].avenantarticles_id,
                            contract_id: horse_actes[i].contract_id,
                            discount_type: discount_type,
                            discount_value: horse_actes[i].discount_value,
                            ht_initial: horse_actes[i].ht_initial ?? horse_actes[i].ht_before_discount * 100,
                            tiershorsepartpart: horse_actes[i].quote_part,
                            tiershorseparttype: 3
                        })
                    }
                }


                let result_invoice_details = false
                if(paramsToSend) {
                    result_invoice_details = await this.createInvoiceDetails(paramsToSend)
                }

                EventBus.$emit("TableAction::stopSpin")

                if(result_invoice_details) {
                    this.$router.push({name: 'invoiceBons'})
                }
                else {
                    this.failureToast();
                    this.$refs["aValiderTable"].unselectAll()
                    this.changeSeason()
                }
            },

            async changeSeason() {
                if(!this.seasonSelected) return
                this.loadAValider(this.seasonSelected.id)
            }
        },
        components: {
            CustomTable: () => import('GroomyRoot/components/Table/CustomTable'),
            SeasonInput : () => import('@/components/Inputs/SeasonInput')
        }
    }
</script>
